import '../App.css';
import React, { useState } from 'react';
import logo from '../logo_v1.png';
import ScrollToTopButton from '../ScrollToTopButton';
import { Link } from 'react-router-dom';

// Header Component
const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="container">
        <div className="logo"><img src = {logo} alt = "logo" class="header-logo"/></div>
        <nav className={`nav ${menuOpen ? 'open' : ''}`}>
        <Link to="/">Startseite</Link>
          <Link to="/datenschutz">Datenschutz</Link>
          <Link to="/impressum">Impressum</Link>
        </nav>
        <button className="cta-button">Kontakt aufnehmen</button>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className={`bar ${menuOpen ? 'change' : ''}`}></div>
          <div className={`bar ${menuOpen ? 'change' : ''}`}></div>
          <div className={`bar ${menuOpen ? 'change' : ''}`}></div>
        </div>
      </div>
    </header>
  );
};


const DatenschutzText = () => (
    <section id="impressum" className="impressum">
        <div className="container">
            <h1>Datenschutzerklärung</h1>

            <h2>Allgemeine Hinweise</h2>

            <p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, 
            wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. 
            Die Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem 
            Zweck das geschieht.</p>

            <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. 
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</p>

            <p>Verantwortlicher und Datenschutzbeauftragter</p>

            <p>Nina Dawert</p>

            <p>Der Verantwortliche im Sinne der Datenschutz-Grundverordnung (DSGVO) und anderer datenschutzrechtlicher Bestimmungen ist:</p>

            <p>DAWERT Immobilienservice</p>

            <p>Nina Dawert</p>

            <p>Thuner Straße 30</p>

            <p>21680 Stade</p>

            <p>Deutschland</p>

            <p>04141 7811313</p> 
            <p>nina@dawert-immoservice.de</p> 
            <p>www.dawert-immoservice.de</p>

            <h2>Kontaktdaten des Datenschutzbeauftragten</h2>

            <p>Der Datenschutzbeauftragte des Verantwortlichen ist:</p>

            <p>Nina Dawert</p>

            <p>Thuner Straße 30</p>

            <p>21680 Stade</p>

            <p>Deutschland</p>

            <p>04141 7811313</p> 
            <p>nina@dawert-immoservice.de</p>

            <p>Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung</p>

            <p>a) Beim Besuch der Website Beim Aufrufen unserer Website www.dawert-immoservice.de werden durch den auf Ihrem Endgerät zum 
            Einsatz kommenden Browser automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden temporär 
            in einem sogenannten Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten 
            Löschung gespeichert:</p>

            <p>- IP-Adresse des anfragenden Rechners,</p>

            <p>- Datum und Uhrzeit des Zugriffs,</p>

            <p>- Name und URL der abgerufenen Datei,</p>

            <p>- Website, von der aus der Zugriff erfolgt (Referrer-URL),</p>

            <p>- verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.</p>

            <p>Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:</p>

            <p>- Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,</p>

            <p>- Gewährleistung einer komfortablen Nutzung unserer Website,</p>

            <p>- Auswertung der Systemsicherheit und -stabilität sowie</p>

            <p>- zu weiteren administrativen Zwecken.</p>

            <p>Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus den oben 
            aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person 
            zu ziehen.</p>

            <p>b) Bei Nutzung unseres Kontaktformulars</p>

            <p>Bei Fragen jeglicher Art bieten wir Ihnen die Möglichkeit, mit uns über ein auf der Website bereitgestelltes Formular Kontakt 
            aufzunehmen. Dabei ist die Angabe einer gültigen E-Mail-Adresse erforderlich, damit wir wissen, von wem die Anfrage stammt und 
            um diese beantworten zu können. Weitere Angaben können freiwillig getätigt werden.</p>

            <p>Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf Grundlage Ihrer 
            freiwillig erteilten Einwilligung.</p>

            <p>Die für die Benutzung des Kontaktformulars von uns erhobenen personenbezogenen Daten werden nach Erledigung der von Ihnen gestellten 
            Anfrage automatisch gelöscht.</p>

            <h2>Weitergabe von Daten</h2>

            <p>Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt.</p>

            <p>Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:</p>

            <p>- Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben,</p>

            <p>- die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen 
            erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe 
            Ihrer Daten haben,</p>

            <p>- für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht, sowie</p>

            <p>- dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen 
            erforderlich ist.</p>

            <p>Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten</p>

            <p>Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten. 
            Wenn diese Tools aktiv sind, können Ihre personenbezogene Daten in diese Drittstaaten übertragen und dort verarbeitet werden. 
            Wir weisen darauf hin, dass in diesen Ländern kein mit der EU vergleichbares Datenschutzniveau garantiert werden kann. 
            Beispielsweise sind US-Unternehmen dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden herauszugeben, 
            ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass US-Behörden 
            (z. B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. 
            Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.</p>

            <h2>Cookies</h2>

            <p>Unsere Website verwendet keine Cookies. Wir setzen keinerlei Cookies ein, um personenbezogene Daten zu 
                erfassen oder das Nutzungsverhalten unserer Besucher zu verfolgen. Sämtliche Funktionen und Inhalte unserer 
                Website sind uneingeschränkt ohne den Einsatz von Cookies zugänglich.</p>

            
            <h2>Hosting</h2>

            <p>Unsere Website wird bei Google Firebase gehostet. Alle durch unsere Website erhobenen Daten werden auf den Servern von Google Firebase
            gespeichert. Google Firebase ist ein Service der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland. 
            Weitere Informationen finden Sie in der Datenschutzerklärung von Google Firebase (https://firebase.google.com/support/privacy).</p>

            <h2>SSL- bzw. TLS-Verschlüsselung</h2>

            <p>Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, 
            nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht 
            mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der 
            Browserzeile.</p>

            <h2>Betroffenenrechte</h2>

            <p>Sie haben das Recht:</p>

            <p>- gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. 
            Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, 
            die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, 
            das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines 
            Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten 
            Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;</p>

            <p>- gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten 
            personenbezogenen Daten zu verlangen;</p>

            <p>- gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die 
            Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, 
            aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;</p>

            <p>- gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit 
            der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht 
            mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß 
            Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;</p>

            <p>- gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und 
            maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;</p>

            <p>- gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, 
            dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen und</p>

            <p>- gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die 
            Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Firmensitzes wenden.</p>

            <h2>Widerspruchsrecht</h2>

            <p>Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet 
            werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, 
            soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. 
            Im letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird.</p>

            <p>Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an nina@dawert-immoservice.de. </p>

    

        </div>
    </section>

);

// Footer Component
const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="logo">Dawert Immobilienservice</div>
      <nav className="nav1">
          <Link to="/">Startseite</Link>
          <Link to="/datenschutz">Datenschutz</Link>
          <Link to="/impressum">Impressum</Link>
    
        
      </nav>
      <p>© 2024 Dawert Immobilienservice. Alle Rechte vorbehalten.</p>
    </div>
  </footer>
);

// Main App Component
const Datenschutz = () => (
  <div className="App">
    <Header />
    <DatenschutzText />
    <Footer />
    <ScrollToTopButton />
  </div>
);

export default Datenschutz;
