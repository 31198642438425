import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import './HeroSlideshow.css';

const images = [
  'Background1.png', // Ersetze durch den tatsächlichen Pfad zu deinem Bild
  'Background2.png',
];

const slides = [
    {
      title: 'DAWERT',
      subtitle: 'Immobilienservice',
      description: 'Ihre vertrauensvolle Hausverwaltung - persönlich, professionell und digital',
    },
    {
      title: '',
      subtitle: '',
      description: '',
    },

  ];

const HeroSlideshow = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextSlide = () => {
    setCurrentImageIndex((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentImageIndex((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const goToSlide = (index) => {
    setCurrentImageIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 1000000); // Bildwechsel alle 10 Sekunden

    return () => clearInterval(interval); // Säubern des Intervalls, wenn die Komponente demontiert wird
  }, []);

  const currentSlide = slides[currentImageIndex];
  const slideClass = `hero-slide-${currentImageIndex + 1}`; // Dynamische CSS-Klasse


  return (
    <div className={`hero-slideshow ${slideClass}`} >
      <img src={require(`./${images[currentImageIndex]}`)} alt="Hero" className="hero-image" />
      <button className="prev" onClick={prevSlide}>
        ❮
      </button>
      <button className="next" onClick={nextSlide}>
        ❯
      </button>
      <div className="hero-content">
    
        <h1>{currentSlide.title}</h1>
        <h2>{currentSlide.subtitle}</h2>
        <p>{currentSlide.description}</p>

        <div className="container1">
      <div className="service-grid1">
        <div className="service1">
          <h3>Mietverwaltung</h3>
          <p>Professionelle Verwaltung Ihrer Mietobjekte, inklusive Nebenkostenabrechnung</p>
        </div>
        <div className="service1">
          <h3>WEG-Verwaltung</h3>
          <p>Umfassende Betreuung von Wohnungseigentümergemeinschaften nach dem Wohnungseigentümergesetz</p>
        </div>
        <div className="service1">
          <h3>Technische Betreuung</h3>
          <p>Instandhaltung und Modernisierung Ihrer Immobilien, stets auf dem neuesten Stand der Technik</p>
        </div>
        {/* Weitere Leistungen */}
      </div>
    </div>
        
      </div>
      <div className="dots">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentSlide ? "active" : ""}`}
            onClick={() => goToSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default HeroSlideshow;
