import '../App.css';
import React, { useState } from 'react';
import logo from '../logo_v1.png';
import ScrollToTopButton from '../ScrollToTopButton';
import { Link } from 'react-router-dom';

// Header Component
const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="container">
        <div className="logo"><img src = {logo} alt = "logo" class="header-logo"/></div>
        <nav className={`nav ${menuOpen ? 'open' : ''}`}>
        <Link to="/">Startseite</Link>
          <Link to="/datenschutz">Datenschutz</Link>
          <Link to="/impressum">Impressum</Link>
        </nav>
        <button className="cta-button">Kontakt aufnehmen</button>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className={`bar ${menuOpen ? 'change' : ''}`}></div>
          <div className={`bar ${menuOpen ? 'change' : ''}`}></div>
          <div className={`bar ${menuOpen ? 'change' : ''}`}></div>
        </div>
      </div>
    </header>
  );
};


const ImpressumText = () => (
    <section id="impressum" className="impressum">
        <div className="container">
        <h2>Impressum</h2>
        <p>Nina Dawert</p>

            <h2>DAWERT Immobilienservice</h2>
            <p>Thuner Straße 30</p>
            <p>21680 Stade</p>
            <br></br>
            <h2>Kontakt</h2>
            <p>Telefon: 04141 7811313</p>
            <p>Telefax: 04141 7811314</p>
            <p>E-Mail: nina@dawert-immoservice.de </p>
            <p>Internetadresse: <a href="https://www.dawert-immoservice.de">https://www.dawert-immoservice.de</a></p>
            <br></br>
            <h2>Erlaubnis nach § 34c Abs. 1 GewO</h2>
            <p>Steuernummer: 43/109/15212</p>
            <p>Finanzamt Stade</p>
            <br></br>
            <h2>Aufsichtsbehörde</h2>
            <p>Industrie- und Handelskammer Stade für den Elbe-Weser-Raum</p>
            <p>Am Schäferstieg 2</p>
            <p>21680 Stade</p>
            <p><a href="https://www.ihk.de/stade/">https://www.ihk.de/stade/</a></p>
            <br></br>
            <h2>Zuständige Berufskammer</h2> 
            <p>Industrie- und Handelskammer für den Elbe-Weser-Raum</p>
            <p>Am Schäferstieg 2</p>
            <p>21680 Stade</p> 
            <p><a href="https://www.ihk.de/stade/">https://www.ihk.de/stade/</a></p>
            <br></br>
            <h2>Berufsbezeichnung</h2>
            <p>Wohnimmobilienverwalter nach § 34c Abs. 1 GewO, Bundesrepublik Deutschland</p>
            <br></br>
            <h2>Angaben zur Berufshaftpflichtversicherung</h2>
            <p>Name und Sitz des Versicherers</p>
            <p>VGH Versicherungen</p>
            <p>30140 Hannover</p>
            <p>Geltungsraum der Versicherung</p>
            <p>Bundesrepublik Deutschland</p>
            <br></br>
            <h2>Redaktionell verantwortlich</h2>
            <p>Nina Dawert</p>
            <p>Thuner Straße 30</p>
            <p>21680 Stade</p>
            <br></br>
            <h2>Webdesign, Umsetzung und Wartung</h2>
            <p>Moritz Dawert</p>
            <p>moritz.dawert@outlook.de</p>
            <br></br>

        </div>
    </section>

);
    



// Footer Component
const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="logo">Dawert Immobilienservice</div>
      <nav className="nav1">
          <Link to="/">Startseite</Link>
          <Link to="/datenschutz">Datenschutz</Link>
          <Link to="/impressum">Impressum</Link>
    
        
      </nav>
      <p>© 2024 Dawert Immobilienservice. Alle Rechte vorbehalten.</p>
    </div>
  </footer>
);

// Main App Component
const Impressum = () => (
  <div className="App">
    <Header />
    <ImpressumText />
    <Footer />
    <ScrollToTopButton />
  </div>
);

export default Impressum;
