import '../App.css';
import React, { useState } from 'react';
import team1 from '../team1.jpg';
import team2 from '../team2.jpg';
import logo from '../logo_v1.png';
import ScrollToTopButton from '../ScrollToTopButton';
import HeroSlideshow from '../HeroSlideshow';
import { Link } from 'react-router-dom';

// Header Component
const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="container">
        <div className="logo"><img src = {logo} alt = "logo" class="header-logo"/></div>
        <nav className={`nav ${menuOpen ? 'open' : ''}`}>
          <a href="#about">Über uns</a>
          <a href="#team">Mitarbeiter</a>
          <a href="#services">Leistungen</a>
          <a href="#contact">Kontakt</a>
        </nav>
        <button className="cta-button">Kontakt aufnehmen</button>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className={`bar ${menuOpen ? 'change' : ''}`}></div>
          <div className={`bar ${menuOpen ? 'change' : ''}`}></div>
          <div className={`bar ${menuOpen ? 'change' : ''}`}></div>
        </div>
      </div>
    </header>
  );
};

// About Section
const About = () => (
  <section id="about" className="about">
    <div className="container">
      <h2>Über uns</h2>
      <p>
        Wir sind ein erfahrenes Hausverwaltungsunternehmen, das seit 15 Jahren
        Immobilien in der Region verwaltet. Unser Ziel ist es, den Wert Ihrer
        Immobilie zu sichern und zu steigern, während wir Ihnen persönlichen und
        professionellen Service bieten.
        Wir setzen auf digitales Immobilienmanagement und bieten Ihnen somit eine moderne und transparente Verwaltung Ihrer Immobilie.
      </p>
      <p></p>
    </div>
  </section>
);

// Team Section
const Team = () => (
  <section id="team" className="team">
    <div className="container">
      <h2>Unser Team</h2>
      <div className="team-grid">
        <div className="team-member">
          <img src={team1} alt="Team Member 1" />
          <h3>Nina Dawert</h3>
          <h3>Geschäftsführerin</h3>
          <p>zertifizierte Verwalterin (§ 26a WEG)</p>
          <p>nina@dawert-immoservice.de</p>
          <p>"Ihre Zufriedenheit ist unsere oberste Priorität."</p>
        </div>
        <div className="team-member">
          <img src={team2} alt="Team Member 2" />
          <h3>Lisa Dawert</h3>
          <h3>Verwaltungsassistentin</h3>
          <p>Bankkauffrau</p>
          <p>lisa@dawert-immoservice.de</p>
          <p>"Wir kümmern uns um Ihre Immobilie mit Herz und Verstand."</p>
        </div>
        {/* Weitere Teammitglieder */}
      </div>
    </div>
  </section>
);

// Services Section
const Services = () => (
  <section id="services" className="services">
    <div className="container">
      <h2>Unsere Leistungen</h2>
      <div className="service-grid">
        <div className="service">
          <h3>Mietverwaltung</h3>
          <p>Professionelle Verwaltung Ihrer Mietobjekte, inklusive Nebenkostenabrechnung</p>
        </div>
        <div className="service">
          <h3>WEG-Verwaltung</h3>
          <p>Umfassende Betreuung von Wohnungseigentümergemeinschaften nach dem WEG</p>
        </div>
        <div className="service">
          <h3>Technische Betreuung</h3>
          <p>Instandhaltung und Modernisierung Ihrer Immobilien, stets auf dem neuesten Stand der Technik</p>
        </div>
        {/* Weitere Leistungen */}
      </div>
    </div>
  </section>
);

const Contact = () => (
    <section id="contact" className="contact">
        <div className="container">
        <button
        onClick={() => window.location.href = "mailto:nina@dawert-immoservice.de"}
        className="email-button">E-Mail senden</button>
      </div>
      <div className="contact-info">
        <p>Adresse: Thuner Str. 30, 21680 Stade</p>
        <p>Telefon: 04141 / 7811313</p>
        <p>Mobil: 0179 / 1145690</p>
        <p>E-Mail: nina@dawert-immoservice.de</p>
      </div>
    </section>
  );

// Contact Section
/*const Contact = () => (
  <section id="contact" className="contact">
    <div className="container">
      <h2>Kontakt</h2>
      <form className="contact-form">
        <input type="text" placeholder="Ihr Name" />
        <input type="email" placeholder="Ihre E-Mail" />
        <textarea placeholder="Ihre Nachricht"></textarea>
        <button type="submit">Nachricht senden</button>
      </form>
      <div className="contact-info">
        <p>Adresse: Thuner Str. 30, 21680 Stade</p>
        <p>Telefon: 04141 / 7811313</p>
        <p>Mobil: 0179 / 1145690</p>
        <p>E-Mail: nina@dawert-immoservice.de</p>
      </div>
    </div>
  </section>
);
*/

// Footer Component
const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="logo">Dawert Immobilienservice</div>
      <div className="nav1">
          <Link to="/datenschutz">Datenschutz</Link>
          <Link to="/impressum">Impressum</Link>
    
        
      </div>
      <p>© 2024 Dawert Immobilienservice. Alle Rechte vorbehalten.</p>
    </div>
  </footer>
);

// Main App Component
const Home = () => (
  <div className="App">
    <Header />
    <HeroSlideshow />
    <About />
    <Team />
    <Services />
    <Contact />
    <Footer />
    <ScrollToTopButton />
  </div>
);

export default Home;
